import React, { useState, useEffect } from "react";
import { Nav, Container, Col, Row } from "react-bootstrap";
import { useStaticQuery, graphql } from "gatsby";
import classNames from 'classnames';
import { Link } from "gatsby";
import LinkRenderer from '../LinkRenderer/LinkRenderer';
import HeaderTop from "./header-top";
import ModalNav from '@components/modal-nav/modal-nav';
import { Logo, LogoChamberlains, BurgerIcon,CloseIcon } from '@components/icon/icon';
import useDeviceMedia from "../../hooks/useDeviceMedia";


import './styles/_index.scss';

const Header = (props) => {
  const [activeModal, setActiveModal] = useState(false);
  const [renderComponent, setRenderComponent] = useState(false);
  const data = useStaticQuery(graphql`
    query BurgerQuery {
      site {
        siteMetadata {
          title
        }
      }
      
    allStrapiAllMenus(filter: {Sort: {}}, sort: {order: ASC, fields: Sort}) {
      edges {
        node {
          id
          Label
          Show_In_Burger_Menu
          URL
          Secondary_URL
          Link_Type
          Hide_Menu
          main_parent {
            URL
            Show_In_Burger_Menu
            Label
            Hide_Menu
          }
          sub_parent {
            URL
            Label
            Hide_Menu
          }
        }
      }
    }    
    }
  `)

  const scriptInsert = () => {
    if (document.getElementById("chat") === null) { 
      var bugherdScript = document.createElement('script');
      bugherdScript.id = 'bugherd';
      bugherdScript.type = 'text/javascript';
      bugherdScript.src = 'https://www.bugherd.com/sidebarv2.js?apikey=90m68owfu7bgakjxu3njya';
      document.getElementsByTagName('body')[0].appendChild(bugherdScript);
    }
  }

  useEffect(() => {
    const handleScroll = () => {
      const stickyHeader = document.querySelector('.sticky-header');
      const scroll = window.scrollY;

      if (stickyHeader) {
        if (scroll >= 40) stickyHeader.classList.add('fixed');
        else stickyHeader.classList.remove('fixed');
      }
    };
    if (renderComponent === false) {
      scriptInsert();
      setRenderComponent(true)
    }
  
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);


  const toggleMenu = () => {
    setActiveModal(!activeModal);
    document.querySelectorAll('body,html').forEach(item => item.classList.toggle('overflow'));
  };

  // Use the useDeviceMedia hook
  const { isCustomMax } = useDeviceMedia({ max: '991.98px' });
   return (
    <>
      <div className="top-header-wraper d-none d-lg-block">
        <HeaderTop sectionData={props?.HeaderTopSection} />
      </div>
      <header className={props.headertype === 'nosticky' ? 'header testt' : 'header sticky-header'}>
      <div className="main-header">
      <Container fluid>
        <Row className="justify-content-between align-items-center">
          <Col xs="auto">
            <Link to="/" className="navbar-brand main-logo"><Logo /></Link>
            <Link to="/" className="navbar-brand chamberlains-logo"><LogoChamberlains /></Link>
          </Col>
          <Col xs="auto" className="d-none d-lg-block ml-auto right-nav">
             <Nav className="mr-auto">
                <ul className="main-nav">
                  {props.Topmenus.edges.map(({node}, key) => (
                    <li key={key} className="d-none d-lg-block">
                      <Link to={node.Menu_Link} className="text-20">
                        {node.Menu_Name}
                      </Link>
                      {node.SubMenu && node.SubMenu.length > 0 && (
                        <ul className="sub-menu">
                          {node.SubMenu.map(sub => (
                            <li key={sub.id}>
                              <Link to={sub.MenuLink} className="text-18">
                                {sub.MenuName}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  ))}
                </ul>
              </Nav>
          </Col>
          {isCustomMax && (
          <Col xs="auto" className="toggle-nav-holder">
              {!activeModal &&
              <ul className="toggle-menu-list">
                  {props?.HeaderTopSection.map((item, index) => (
                    <React.Fragment key={item.id}>
                      <li className={item.Hide_Mobile ? 'hide-mobile' : ''}><LinkRenderer item={item} /></li>                      
                    </React.Fragment>
                  ))}
              </ul>
              }
              <button 
                className={classNames('toggle-nav', {'is-active': activeModal})} 
                onClick={toggleMenu}
              >
               {activeModal ? <CloseIcon /> : <BurgerIcon /> } 
              </button>

          </Col>
          )}
        </Row>
      </Container>
      </div>    
       {isCustomMax && (
            <ModalNav BurgerMenu={props.Topmenus} active={activeModal} toggle={setActiveModal} /> 
        )} 
      </header>
    </>
  )
}

export default Header;
