import React from 'react';
import HtmlParser from 'react-html-parser';
import CTARenderer from '../CTARenderer/CTARenderer';

const FooterTop = ({topContent}) => {
  return (
    <div className="footer-top">
       {topContent?.Footer_Top_Title && <h3>{topContent?.Footer_Top_Title}</h3>}
       {topContent?.Footer_Top_Content && HtmlParser(topContent?.Footer_Top_Content)}
       {(topContent?.Footer_Top_CTA_Link?.URL || topContent?.Footer_Top_CTA_Link2?.URL) && 
         <div class="footer-cta">
            <CTARenderer CTA1Label={topContent?.Footer_Top_CTA_Label} CTA1Link={topContent?.Footer_Top_CTA_Link?.URL} CTA2Label={topContent?.Footer_Top_CTA_Label2} CTA2Link={topContent?.Footer_Top_CTA_Link2 ?.URL} btn1class="btn-yellow" btn2class="btn-transparent-black" />
          </div>
        }
    </div>
  );
};

export default FooterTop;