import React from "react";
import { getSpacingClass } from "../../common-data/common-data";
import HomeBoxes from "@components/home-boxes/home-boxes";

import './TileBlocksModule.scss';

const TileBlocksModule = ({ ModuleData }) => {
  console.log('ModuleData', ModuleData)
  const spacingClass = getSpacingClass(ModuleData?.ModuleSpacing);
  const imageLength = ModuleData && ModuleData?.Tiles && ModuleData?.Tiles.find((dat)=> dat.Tile_Image != null);
  if(imageLength > 0){
    return (
      <div className={`tile-blocks-module ${spacingClass}`}>
        <HomeBoxes data={ModuleData?.Tiles}  />
      </div>
    );
  }else{
    return <></>
  }
 
};

export default TileBlocksModule;