import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Img from "gatsby-image";
import PlayIcon from "@components/play-icon/play-icon";
import ReactPlayer from "react-player";
import getVideoId from "get-video-id";
import "./FullWidthModule.scss";
import ImageRenderer from "@components/ImageRenderer/ImageRenderer";

const FullWidthModule = ({ ModuleData, ...PageData }) => {

    const [show, setShow] = useState(false);
    const [play, setPlayVideo] = useState(false);
    const [showHTMLVideo, setHTMLVideo] = useState(false);
    let imageurl =''

    const url = typeof window !== "undefined" ? window.location.href : "";
    const trackerVideo = () => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'Video Play Btn',
            'formType': url,
            'formId': 'Video Play',
            'formName': 'Video',
            'formLabel': 'Video'
        });
    }
    const image_url = ModuleData?.Full_Width_Image?.internal.description ? ModuleData.Full_Width_Image.internal.description.replace(/File |"/g, '')
        : '';
        

    let video_url = '';
    if (ModuleData?.HTMLVideo) {
        video_url = ModuleData.HTMLVideo.internal.description
            ? ModuleData.HTMLVideo.internal.description.replace("File ", "").replace('"', '').replace('"', '')
            : '';
    }
    if (ModuleData?.Full_Width_Video_Embed_URL) {
        const youtube_id = getVideoId(ModuleData?.Full_Width_Video_Embed_URL);
        if (youtube_id.service === "youtube") {
            video_url = "https://www.youtube-nocookie.com/embed/" + youtube_id.id;
        } else {
            video_url = ModuleData?.Full_Width_Video_Embed_URL;
        }
    }
    return (
        <div className="fullwidth-module">
            {showHTMLVideo &&
                <Modal
                    show={show}
                    onHide={() => setShow(false)}
                    dialogClassName="modal-video"
                    aria-labelledby="video-module"
                    backdropClassName="video-backdrop"
                >
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <ReactPlayer
                            url={video_url}
                            playing={play}
                            controls={true}
                            width='100%'
                            height='100%'
                            onPlay={trackerVideo}
                            onEnded={() => { setHTMLVideo(false) }}
                        />
                    </Modal.Body>
                </Modal>
            }
            {ModuleData?.Full_Width_Display_Type === 'Video' && (
                <div className="fullwidth-video-module">
                        <ImageRenderer ImageSrc={{url:image_url}} altText={ModuleData?.Title} ggfx_results={PageData?.ggfx_results} strapi_id={PageData?.StrapiId} imagename="articles.images.BannerImage"  />
                        {ModuleData?.Full_Width_Video_Embed_URL &&
                            <div className="video-play-btn"
                                onClick={() => { setHTMLVideo(true); setPlayVideo(true); setShow(true); }}
                            >
                                <PlayIcon size="large"/>
                            </div>
                        }
                </div>
            )}
            {ModuleData?.Full_Width_Display_Type === 'Image' && (
                <div className="fullwidth">
                    <ImageRenderer ImageSrc={{url:image_url}} altText={ModuleData?.Title} ggfx_results={PageData?.ggfx_results} strapi_id={PageData?.StrapiId} imagename="articles.images.BannerImage"  />
                </div>
            )}
        </div>
    );
};

export default FullWidthModule;