import React, { useState, useEffect } from "react";
import classNames from 'classnames';
import { Container } from 'react-bootstrap';
import { useQuery } from '@apollo/client';
import gql from "graphql-tag";
import { Link } from "gatsby";
import { Logo } from '@components/icon/icon';
import ImageRenderer from "@components/ImageRenderer/ImageRenderer";
import DateFormat from '@components/format-date/';
import { useInView } from 'react-intersection-observer';
import InnerPagination from "@components/InnerPagination/InnerPagination";

import './styles/_index.scss';

const GET_ALL_NEWS = gql`
  query GETNEWS($sort: String!, $where: JSON!, $limit: Int!, $start: Int!) {
    newsEvents(sort: $sort, where: $where, limit: $limit, start: $start) {
      id
      Title
      URL
      Content
      Featured
      Banner_Image {
        url
      }
      Published_Date
      ggfx_results {
        id
        transforms
        src_cftle
      }
    }
    newsEventsConnection(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

const NewsOverviewGrid = () => {
  const [ref, inView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });
  const itemsPerPage = 9;
  const [currentPage, setCurrentPage] = useState(1);

  const { loading: queryLoading, error, data, fetchMore } = useQuery(GET_ALL_NEWS, {
    variables: {
      sort: "Published_Date:desc",
      where: { Featured: true },
      limit: itemsPerPage,
      start: (currentPage - 1) * itemsPerPage,
    },
  });

  const [testimonials, setTestimonials] = useState([]);
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    if (data) {
      setTestimonials(data.newsEvents);
      setTotalItems(data.newsEventsConnection.aggregate.count);
    }
  }, [data]);

  const ImageRender = ({ item }) => {
    return (
      <ImageRenderer
        ImageSrc={item.Banner_Image}
        altText={{ alt: item.Title + ' - Anthony Pepe' }}
        ggfx_results={item.ggfx_results}
        strapi_id={item?.id}
        imagename="news-events.Banner_Image.teamTitle"
      />
    );
  };

  const loadMoreItems = () => {
    fetchMore({
      variables: {
        start: testimonials.length,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return {
          ...prev,
          newsEvents: [...prev.newsEvents, ...fetchMoreResult.newsEvents],
        };
      },
    });
  };

  if (queryLoading && testimonials.length === 0) {
    return (
      <div className="static-gql-loading">
        <Container>
          <Logo />
          <div className="gql-loading">Loading ...</div>
        </Container>
      </div>
    );
  }

  if (queryLoading && testimonials.length > 0) {
    return (
      <div className="static-gql-loading">
        <Container>
          <Logo />
          <div className="gql-loading">Loading More News...</div>
        </Container>
      </div>
    );
  }

  if (error) {
    return null;
  }

  if (testimonials.length === 0) {
    return (
      <div className="static-gql-loading">
        <Container>
          <Logo />
          <div className="gql-loading">No featured news events found</div>
        </Container>
      </div>
    );
  }

  return (
    <div className="static-wave">
      <div className={classNames('wave news-wave', { 'is-active': inView })} ref={ref}>
        <Container>
          <div className="news-list row">
            {testimonials.length > 0 &&
              testimonials.map((item) => (
                <div className="news-list-item col-lg-4 col-md-6 col-sm-12" key={item.id}>
                  <div className="image-container">
                    <div className="image">
                      <Link to={`${item.URL}/`}>
                        <ImageRender item={item} />
                      </Link>
                    </div>
                  </div>
                  <div className="text-box-container">
                    <div className="img-text-box">
                      <span className="text-18 Published_Date"><DateFormat className="text-18" date={item.Published_Date} /></span>
                      <Link to={`${item.URL}/`}><h3 className="text-22 bold">{item.Title}</h3></Link>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          {testimonials.length < totalItems && (
            <div className="pagination load-more-wrap news-grid-list row">
              <div className="col-md-12">
                <InnerPagination
                  currentPage={currentPage}
                  itemsPerPage={itemsPerPage}
                  totalItems={totalItems}
                  setCurrentPage={setCurrentPage}
                  loadMore={loadMoreItems}
                />
              </div>
            </div>
          )}
        </Container>
      </div>
    </div>
  );
};

export default NewsOverviewGrid;