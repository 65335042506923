import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import ModuleRenderer from "../components/ModuleRenderer/ModuleRenderer";

const HomePageTemplate = (props) => {
    //console.log("props", props);

    const Page = props.data?.strapiArticles;
    const PageModules = props.data?.strapiArticles?.Modules;
    const ReviewsData = props.data?.strapiGlobalConfig?.Elfsight_Reviews;
    const FormData = props.data?.strapiGlobalConfig?.GetStartedForm;
    const classAttribute = `homepage-wrap ${Page?.Layout || ''} ${Page?.Custom_CSS_Class || ''}`;

    return (
        <Layout classAttribute={classAttribute} pageType="home" popularSearch={Page?.Select_Popular_Search}>
            <SEO title={Page?.Meta_Title} description={Page?.Meta_Description} />
            <ModuleRenderer Modules={PageModules} StrapiId={Page?.strapiId} ggfx_results={Page?.ggfx_results} PageAlias={Page?.Alias} TeamData={Page?.Team_Members} ReviewsData={ReviewsData} FormData={FormData} />
        </Layout>
    );
};

export default HomePageTemplate;

export const pageQuery = graphql`
  query HomeQuery($id: String!) {
    strapiArticles(strapiId: {eq: $id}) {
      id
      strapiId
      Pagename
      Alias
      Meta_Title
      Meta_Description
      Layout
      Custom_CSS_Class
      Select_Popular_Search
      Team_Members {
        id
        Name
        Image {
          internal {
            description
          }
          childImageSharp {
            fluid(maxWidth: 100) {
                ...GatsbyImageSharpFluid
            }
          }
        }
        ggfx_results
      }
      ggfx_results {
       transforms {  
            format
            transform
            url 
        }
        src_import_url
        src_cftle
      }
      Modules {
        ModuleType
        ModuleSpacing     
        Banner_Title
        Show_Banner_Title_Spacing
        Banner_Content
        Banner_Image {
          internal {
            description
          }
        }        
        Banner_CTA_1_Label        
        Banner_1_CTA_Hash
        Banner_1_Custom_Link
        Banner_CTA_2_Label
        Banner_2_CTA_Hash
        Banner_2_Custom_Link        
        Show_Search
        Show_Review
        Full_Width_Display_Type
        Full_Width_Image {
          internal {
            description
          }
          childImageSharp {
            fluid(quality: 90, maxWidth: 1600) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        Full_Width_Video_Embed_URL
        Intro_Content
        Intro_Title
        Content_Column_Left
        Content_Column_Right 
        Child_Pages {
        Child_Page_Label
        Child_Page_Link {
          URL
        }
        Child_Page_Custom_Link
        }       
        Full_Width_Two_Col_Title      
        Full_Width_Two_Col_Content
        Full_Width_Two_Col_CTA_Label
        Full_Width_Two_Col_CTA_URL
        Full_Width_Two_Col_Image {
          internal {
            description
          }
          childImageSharp {
            fluid(quality: 90, maxWidth: 1600) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        Tiles {
          Title
          Content
          Tile_Position
          Tile_Image {
            childImageSharp {
                fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                }
            }
          }      
          CTA_Label
          Tile_URL {
            URL
          }
        }
        Section_Title
        Section_Intro
        FormList
        ModuleBGColor
        Show_Contact
        FormContactData {
          Label
          Link
          Type
          Icon_Type
          Show_Icon
        }
        Select_Module
        Select_Module_Title
        Select_Module_Content
        Widget_Code
      }
    }
    strapiGlobalConfig {
      Elfsight_Reviews {
        Badge_Widget_Code
        Carousel_Widget_Code
        Widget_Embed_Video_URL
        Percentage_value
        Total_reviews
        Widget_Video_Bg_Image {
          internal {
            description
          }
          childImageSharp {
            fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
            }
          }
        }
      }
      GetStartedForm {
        Section_Title
        Section_Intro
        FormList
        FormContactData {
          Label
          Link
          Type
          Icon_Type
          Show_Icon
        }
      }
    }
  }
`;